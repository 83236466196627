import React, { useState } from "react"
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const InspirationBlocks = (props) => {
  return props.content.map((item, index) =>
      <>
      {props.current === item.node.destination._meta.uid ? 
      <Link className="content-blocks__item" key={index} to={linkResolver(item.node)}>
          {item.node.hero_image ? 
          <Img className="content-blocks__image" fluid={item.node.hero_imageSharp.childImageSharp.fluid} alt={item.node.hero_image.alt} />
          : ""}
          <div className="content-blocks__copy">
            {item.short_name ? 
            <h3><span>{item.node.numeral ? item.node.numeral : ""}</span>{item.short_name}</h3>
            :
            <h3><span>{item.node.numeral ? item.node.numeral : ""}</span>{RichText.asText(item.node.title)}</h3>
            }
            <span className="cta">Read more</span>
          </div>
      </Link>
      : ""}
      </>
  )
}

export default InspirationBlocks;
