import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

function renderCurated(slice) {
  return slice.fields.map((item, index) =>
  <>
  <Link className="content-blocks__item" key={index} to={linkResolver(item.link)}>
          {item.image ? 
          <Img className="content-blocks__image" fluid={item.imageSharp.childImageSharp.fluid} alt={item.image.alt} />
          : item.link.imageSharp ? 
          <Img className="content-blocks__image" fluid={item.link.imageSharp.childImageSharp.fluid} alt={item.link.image.alt} />
          :
          <Img className="content-blocks__image" fluid={item.link.hero_imageSharp.childImageSharp.fluid} alt={item.link.hero_image.alt} />
          }
          {/* {!item.category || item.category[0].text.length < 1 ? 
          <span className="category">{item.link._meta.type === "news_content" ? "News & travel tips" : item.link._meta.type}</span>
          : 
          <span className="category">{RichText.asText(item.category)}</span>
          } */}
          {item.short_name ? 
          <h3>{item.link.short_name}</h3>
          : !item.title1 ?
          <h3>{RichText.asText(item.link.title)}</h3>
          : 
          <h3>{RichText.asText(item.destination_name)}</h3>}
          {/* {item.link.introduction && item.link._meta.type === "news_content" ?
          <p className="excerpt">{RichText.asText(item.link.introduction)}</p>
          : ""} */}
      </Link> 
      </>
  )
}

export default ({ slice }) =>
  <>
    <div className="wrapper">
      <div className="content-blocks">
        {slice.primary.section_title ? 
        <h2>{RichText.asText(slice.primary.section_title)}</h2>
        : ""}
        <div className={slice.fields.length === 3 ? "content-blocks__items" : "content-blocks__items--grid"}>
          {renderCurated(slice)}
        </div>
        {slice.primary.link ? 
        <Link className="button button--primary" to={linkResolver(slice.primary.link)}>{slice.primary.link_text}</Link>
        : ""}
      </div>
    </div>
  </>