import React from 'react'
import { graphql } from 'gatsby';
import RenderSlices from '../01 Atoms/slicesHelper'
import Layout from '../04 Templates/layout'

export const query = graphql`
query ContentPageQuery($uid: String!) {
    prismic {
        allContents(uid: $uid) {
          edges {
            node {
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }              
              introduction
              title
              _meta {
                tags
                uid
                type
              }
              body {
                ... on PRISMIC_ContentBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
`
  
  const RenderBody = ({ home, slices }) => (
    <React.Fragment>
        <article>
            <RenderSlices slices={home.node} />
        </article>
    </React.Fragment>
  );
  
  const Content = data => {
    const doc = data.data.prismic.allContents.edges.slice(0,1).pop();
    if(!doc) return null;
    return(
      <Layout data={doc}>
          <RenderBody home={doc}/>
      </Layout>
    )
  }
  
  export default Content;