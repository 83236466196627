import React from 'react'
import { RichText } from 'prismic-reactjs'
import GalleryComponent from '../01 Atoms/galleryComponentSlice'
import { Link } from 'gatsby'


export default ( slice ) =>
<>
  <div className="wrapper">
    <div className="content-blocks content-blocks--gallery">
      {slice.slice.primary.title ? 
        <h2>{RichText.asText(slice.slice.primary.title)}</h2>
      : ""}    
      <div className={"content-blocks__items--grid content-blocks__items--gallery"}>
          <GalleryComponent data={slice.slice.primary.button_link.body[0].fields} />
      </div>
      {slice.slice.primary.button_link ? 
        <Link className="button button--primary" to='/gallery'>{RichText.asText(slice.slice.primary.button_text)}</Link>
        : ""}      
    </div>
  </div>
</>