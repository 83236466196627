import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { RichText } from 'prismic-reactjs'
import TextTruncate from 'react-text-truncate'

import { withStyles, ButtonBase } from "@material-ui/core"

const style = theme => ({
  previewButton: {
    display: "inline-block",
    background: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100%",
    borderRadius: 4,
    boxShadow: "0 0 15px -5px rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    left: 0,
  },
  thumbnail: {
    width: "100%",
    borderRadius: 4,
  },
})

const ThumbGrid = ({ images, handleOpen, classes }) => {
  return images.map((image, i) => (
    <>
    {i < 15 ? 
    <div className="gallery__item">
    {image.imageSharp ? 
        <Img alt={RichText.asText(image.caption)} className="gallery__image" key={i} fluid={image.imageSharp.childImageSharp.fluid} />
    : ""}
        <div className="gallery__caption">
            <TextTruncate
                  line={5}
                  className=""
                  element="span"
                  truncateText=" … "
                  text={RichText.asText(image.caption)}
              />            
        </div>
        <ButtonBase
            onClick={handleOpen(i)}
            className={classes.previewButton}
            key={i}
        >        
        </ButtonBase>
    </div>
    : ""}
    </>
  ))
}

ThumbGrid.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array,
}
export default withStyles(style)(ThumbGrid)
