import React from 'react';
import {classifyString} from '../06 Utilities/filterStates'
import MultiSelect from "react-multi-select-component";

const Filter = (props) => {

    const name = classifyString(props.item);
    const label = props.item;

    return(
        <div className="input-wrap">
          <input 
            onClick={(e) => props.handleChange(props.item, e.target, props.content)}
            value={name}
            name={name}
            type="checkbox" />
          <label htmlFor={name}>{label}
          </label>
        </div>
    )
  
};

export default Filter;
