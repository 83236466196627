import React, { useState } from "react"
import { RichText } from 'prismic-reactjs'
import Filter from '../01 Atoms/filter'
import AutoBlock from '../01 Atoms/AutoBlocks'
import InspirationBlocks from '../01 Atoms/InspirationBlocks'

const AutoFeed = ({ slice, content, current }) => {
  const [selectedTag, setSelected] = useState([]);

  function handleChange( item, target, content ) {
    if (target.checked) {
      return setSelected([...selectedTag, item]);
    }
    if (!target.checked) {
      let result = selectedTag.indexOf( ({ label }) => label === target.name );
      selectedTag.splice(result, 1);
      return setSelected([...selectedTag]);
    }
  }

  let tags = [];
  const tagArr = [];

  function concat(...args) {
    return args.reduce((acc, val) => [...acc, ...val]);
  }    

  content.forEach(element => {
      tags = concat(element.node._meta.tags);
      tags.map(tag => {
        let res = tagArr.find(el => el === tag);
        if (!res) {
          tagArr.push(tag);
        }
      });
  });  

  return (
    <>
    <div className="wrapper">
      <div className="content-blocks">
        {
          content.title ? 
          <h2>{RichText.asText(content.title)}</h2>
          : ""
        }
        {
          content[0].node._meta.type === "destination" ?
          <div className="filter-wrapper">
            <h3>Filter by tag</h3>
            <form className="form form--filter">
              <div className="filter-wrap">
              {tagArr.map((item, index) =>        
                <Filter key={index} handleChange={handleChange} item={item} content={content} />
              )}
              </div>
            </form>
          </div>
          : ''
        }
        {
          slice.primary.heading || slice.primary.intro ? 
          <div className="auto-feed-intro">
            {slice.primary.heading ? RichText.render(slice.primary.heading) : ''}
            {slice.primary.intro ? RichText.render(slice.primary.intro) : ''}
          </div>
          : ""
        }                
        <div className={content.length >= 3 ? "content-blocks__items" : "content-blocks__items--grid"}>
          {
            content[0].node._meta.type === "inspiration" && content ? 
            <InspirationBlocks current={current} content={content} />
          :
          content.map((destination, index) => 
            <AutoBlock key={index} destination={destination} selectedTag={selectedTag} />
          )
          }
        </div>
      </div>
    </div>
    </>
  )
}

export default AutoFeed;