import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'

export default ({ slice }) =>
<>
<div className="wrapper">
  <div className="content">
    {RichText.render(slice.primary.content, linkResolver)}
  </div>
</div>
</>