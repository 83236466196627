import React, { useState } from "react"
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import TextTruncate from 'react-text-truncate'
import { classify } from '../06 Utilities/filterStates'

const AutoBlock = (props) => {  
    let res = props.destination.node._meta.tags.find(el => el === props.selectedTag.find(tag => tag === el));
    if (res || props.selectedTag && props.selectedTag.length === 0) {
    return (
    <>
        {/* {props.destination.node._meta.tags.filter(element => props.selectedTag.find(label => label === element)).map(element => ( */}
        <Link 
        className={
          "content-blocks__item"
        }
        to={linkResolver(props.destination.node)}
        >
            {props.destination.node.hero_image ? 
            <Img className="content-blocks__image" fluid={props.destination.node.hero_imageSharp.childImageSharp.fluid} alt={props.destination.node.hero_image.alt} />
            : ""}
            <div className={props.destination.node.introduction ? props.destination.node.introduction[0].text || props.destination.node.content ? "content-blocks__copy" : "content-blocks__copy content-blocks__copy--nointro" : "content-blocks__copy"}>
            {props.destination.node.short_name ? 
              <h3>{props.destination.node.short_name}</h3>
              :
              <h3>{RichText.asText(props.destination.node.title)}</h3>
            }
              {props.destination.node.introduction ? props.destination.node.introduction[0].text ? 
              <TextTruncate
                  line={2}
                  className="excerpt"
                  element="p"
                  truncateText=" … "
                  text={RichText.asText(props.destination.node.introduction)}
              />
              :
              <TextTruncate
                  line={2}
                  className="excerpt"
                  element="p"
                  truncateText=" … "
                  text={RichText.asText(props.destination.node.content)}
              />  
              : ""}
            </div>
        </Link>
        {/* ))} */}
    </>
    )} else return null;
  }

export default AutoBlock;