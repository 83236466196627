import React from 'react'
import ContentBlocksSlice from '../03 Organisms/ContentBlocksSlice'
import ContentSlice from '../03 Organisms/ContentSlice'
import InlineImage from '../03 Organisms/InlineImageSlice'
import GalleryFeedSlice from '../03 Organisms/GalleryFeedSlice';
import AutoBlockSlice from '../03 Organisms/AutoBlockSlice';

const RenderSlices = ({ slices }) => {
    let doc;
    !slices.body ? doc = slices : doc = slices.body;
    return doc.map((slice, index, insta, content, feed) => {
      const res = (() => {
        switch(slice.type) {
            case 'inspiration_links': return (
    
                <section key={index} className="homepage-slice-wrapper links-slice">
                    <ContentBlocksSlice slice={slice} />
                </section>

            )

            case 'auto_content_feed': return (
                <section key={index} className="homepage-slice-wrapper">
                  <AutoBlockSlice content={content} feed={feed} slice={slice} />
                </section>
    
            )            

            case 'content': return (

                <section key={index} className="homepage-slice-wrapper content-slice">
                    <ContentSlice slice={slice} />
                </section>

            )
            
            case 'inline_image': return (

                <section key={index} className="homepage-slice-wrapper image-slice">
                    <InlineImage slice={slice} />
                </section>

            )
            
            case 'gallery': return (
    
                <section key={index} className="homepage-slice-wrapper gallery-slice">
                    <GalleryFeedSlice content={content} insta={insta} />
                </section>

            )               

          default: return

        }
      }
      )();
      return res;
    })
  }

  export default RenderSlices;