import React from 'react'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'

export default ({ slice }) =>
  <>
    <div className="wrapper">
      <div className="content-blocks content-blocks--image-block">
        <Img className="content-blocks--image-block__image" fluid={slice.primary.imageSharp.childImageSharp.fluid} alt={slice.primary.image.alt} />
        {slice.primary.caption && slice.primary.caption[0].text ? 
        <div className="caption">
            {RichText.render(slice.primary.caption)}
        </div>
        : ""}
      </div>
    </div>
  </>